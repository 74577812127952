.crt-container {
	background-color: black;
	position: fixed;
	inset: 0;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	will-change: transform;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
}

.loading-screen {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
	z-index: 10;
}

.loading-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.spinner {
	width: 30px;
	height: 30px;
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-top: 4px solid #ffffff;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	will-change: transform;
}

.loading-text {
	color: #ffffff;
	margin-top: 10px;
	font-size: 1.2rem;
	font-family: system-ui, -apple-system, sans-serif;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.main-canvas {
	width: 100vw;
	height: 100vh;
	opacity: 1;
	transition: opacity 0.3s ease-in;
	will-change: opacity;
}

.hidden-canvas {
	display: none;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}