* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
}

/* Optimized font-face declarations */
@font-face {
  font-family: 'CustomFont1';
  src: local('Monoton-Regular'),
    url('/fonts/Monoton-Regular.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont2';
  src: local('ITCAvantGardeStd-Bold'),
    url('/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont3';
  src: local('BakbakOne-Regular'),
    url('/fonts/BakbakOne-Regular.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont4';
  src: local('Notable-Regular'),
    url('/fonts/Notable-Regular.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CustomFont5';
  src: local('Faustina'),
    url('/fonts/Faustina-VariableFont_wght.woff2') format('woff2');
  font-display: swap;
  font-weight: 100 900;
  font-style: normal;
}

/* Performance optimizations */
.crt-container {
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Smooth transitions */
canvas {
  will-change: opacity;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* Loading animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  will-change: transform;
}